<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2">
                <i class="uil uil-money-withdraw display-6 text-primary"></i>
              </div>
              <div>
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }}
                </h4>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label mb-1">Merchant Contract</label>
                <multiselect
                  ref="select"
                  v-model="selectedmerchantContract"
                  :options="merchantContract"
                  label="account_name"
                  placeholder="Select"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  :show-on-select="true"
                  :disabled="disabled"
                  @open="isOpen = true"
                  @close="isOpen = false"
                  @input="handlePageChange(1)"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.contract_id !== ''">
                      <span
                        v-if="props.option.contract_status == 'unavailable'"
                        class="text-warning me-1"
                        >&#9679;</span
                      >
                      <span
                        v-else-if="props.option.contract_status == 'available'"
                        class="text-success me-1"
                        >&#9679;</span
                      >
                      <span class="option__title">
                        {{ props.option.account_name }}:
                        {{ props.option.contract_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.contract_name }} </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.contract_id !== ''">
                      <span
                        v-if="props.option.contract_status == 'unavailable'"
                        class="text-warning me-1"
                        >&#9679;</span
                      >
                      <span
                        v-else-if="props.option.contract_status == 'available'"
                        class="text-success me-1"
                        >&#9679;</span
                      >
                      <span class="option__title">
                        {{ props.option.account_name }}:
                        {{ props.option.contract_name }}
                      </span>
                    </span>
                    <span v-else>{{ props.option.contract_name }}</span>
                  </template>
                  <span slot="noResult">Oops! No Contract found. </span>
                </multiselect>
              </div>
              <div class="col-12 col-md-8">
                <div class="row">
                  <div
                    class="col-12 col-md-3 px-md-1 mb-3"
                    :class="{ 'col-md-4': account_type !== 'admin' }"
                  >
                    <label class="form-label mb-1">Method</label>
                    <select
                      class="form-select pe-5 font-size-14"
                      v-model="seletedMethod"
                      @change="handlePageChange(1)"
                      :disabled="disabled"
                    >
                      <option value="">All</option>
                      <option value="auto">Auto</option>
                      <option value="manual">Manual</option>
                    </select>
                  </div>
                  <!-- <div class="col-12 col-md-3 px-md-1 mb-3" :class="{'col-md-4':account_type!=='admin'}">
                    <label class="form-label mb-1">Status</label>
                    <select class="form-select pe-5 font-size-13" v-model="selectedStatus" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All Status</option>
                      <option value="pending">Processing</option>
                      <option value="success">Successful</option>
                      <option value="failed">Failed</option>
                    </select>
                  </div> -->
                  <div
                    class="col-12 col-md-4 px-md-1 mb-3"
                    :class="{ 'col-md-4': account_type !== 'admin' }"
                  >
                    <label class="form-label mb-1">Country</label>
                    <multiselect
                      v-model="selectedCountry"
                      :options="countryList"
                      label="name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="countryChanged"
                    >
                      <span slot="noResult">Oops! No Country found. </span>
                    </multiselect>
                  </div>
                  <div
                    class="col-12 col-md col-xl px-md-1 mb-3"
                    v-if="account_type == 'admin'"
                  >
                    <label class="form-label mb-1">Payment Gateway</label>
                    <multiselect
                      v-model="selectedPg"
                      :options="returnPgList()"
                      label="payment_gateway_name"
                      class=""
                      placeholder="Select"
                      :close-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      :show-on-select="true"
                      :disabled="disabled"
                      @input="handlePageChange(1)"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.id !== ''">
                          <span
                            v-if="props.option.status !== 1"
                            class="text-danger me-1"
                            >&#9679;</span
                          >
                          <span v-else class="text-success me-1">&#9679;</span>
                          <span class="option__title">{{
                            props.option.payment_gateway_name
                          }}</span>
                        </span>
                        <span v-else>
                          {{ props.option.payment_gateway_name }}
                        </span>
                      </template>

                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.id !== ''">
                          <span
                            v-if="props.option.status !== 1"
                            class="text-danger me-1"
                            >&#9679;</span
                          >
                          <span v-else class="text-success me-1">&#9679;</span>
                          <span class="option__title">{{
                            props.option.payment_gateway_name
                          }}</span>
                        </span>
                        <span v-else>
                          {{ props.option.payment_gateway_name }}
                        </span>
                      </template>
                      <span slot="noResult"
                        >Oops! No Payment Gateway found.
                      </span>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1"
                  >Transaction ID
                  <span v-if="account_type == 'admin'"
                    >/ PG Transaction ID</span
                  ></label
                >
                <div>
                  <input
                    type="text"
                    placeholder="Transaction Id"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchTransactionId"
                    @keyup.enter="search()"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Reference No. / Merchant Remarks</label>
                <div>
                  <input
                    type="text"
                    placeholder="Reference No."
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchReferenceNo"
                    @keyup.enter="search()"
                  />
                </div>
              </div>
              <div class="col-12 col-md mb-3">
                <label class="form-label mb-1">Amount</label>
                <div>
                  <input
                    type="tel"
                    @input="inputNumberOnly($event, 'searchAmount')"
                    placeholder="Amount"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchAmount"
                    @keyup.enter="search()"
                  />
                </div>
              </div>
              <div class="col-12 col-md-auto text-md-end mb-3 mb-lg-0">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="d-flex w-100">
                  <button
                    class="btn btn-light col-auto mb-0 me-2 text-nowrap d-grid"
                    type="button"
                    :disabled="disabled"
                    @click="clear()"
                  >
                    <span><i class="uil-redo me-1"></i> Clear</span>
                  </button>
                  <button
                    class="btn btn-info text-white me-0 text-nowrap d-grid"
                    type="button"
                    :disabled="disabled"
                    @click="search()"
                  >
                    <span><i class="uil-search me-1"></i> Search</span>
                  </button>
                </div>
              </div>
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg col-xl d-none">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                  <select class="form-select pe-5" v-model="filterType">
                    <option value="created_date">Created Date</option>
                    <option value="updated_date">Updated Date</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md col-xl mb-0 d-none">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>

              <div class="col-12 col-md-auto col-xl-auto d-none">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12 col-md-4 mb-3">
                <div class="d-md-flex justify-content-between bg-soft-purple p-3 rounded">
                  <div class="fw-medium">Total Amount</div>
                  <div class="fw-medium" v-if="returnTotalAmount">{{ convertCurrencyFormat(returnTotalAmount, false) || 0 }}</div>
                  <div class="fw-medium" v-else>0</div>

                </div>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <div class="d-md-flex justify-content-between bg-soft-purple p-3 rounded">
                  <div class="fw-medium">Total Transactions</div>
                  <div class="fw-medium" v-if="totalData">{{ convertCurrencyFormat(totalData, false) || 0 }}</div>
                  <div class="fw-medium" v-else>0</div>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <div class="d-md-flex justify-content-between bg-soft-success p-3 rounded">
                  <div class="fw-medium">Success Rate </div>
                  <div class="fw-medium">{{returnSuccessRate.toFixed(2) || 0}}% ({{ returnTotalTransaction }}/{{ totalData}}) </div>
                </div>
              </div>
            </div> -->
            <div class="text-end">
              <b-dropdown variant="secondary" size="sm" right>
                <template v-slot:button-content>
                  Change Time Zone By Country ({{ selectedTimezone }})
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item
                  href="javascript:void(0)"
                  :value="value"
                  v-for="(value, index) in timezoneCountry"
                  :key="index"
                  @click="selectedTimezone = value; handlePageChange(1)"
                  >{{ value }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <table
                id="freeze"
                class="table mt-2 mb-0 align-middle min-width-760"
                :class="{ 'table-hover': returnData.length > 0 && !pageLoading }"
              >
                <thead class="text-uppercase">
                  <tr>
                    <th>Deposit ID<br>Transaction ID</th>
                    <th>Reference No.<br>Merchant Remarks</th>
                    <th>Created Date<br />Updated Date</th>
                    <th>Merchant<br />Contract</th>
                    <th v-if="account_type == 'admin'">
                      Payment Gateway<br />Callback Data
                    </th>
                    <th>Method</th>
                    <th>Deposit Amount</th>
                    <th>Total Charges</th>
                    <th>Nett Amount</th>
                    <th>Charges Rate</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !pageLoading">
                    <td
                      :colspan="account_type == 'admin' ? 12 : 11"
                      height="300"
                      class="text-center text-muted"
                    >
                      <i class="uil uil-money-withdraw me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="pageLoading">
                    <td
                      :colspan="account_type == 'admin' ? 12 : 11"
                      class="text-center text-muted"
                      height="400"
                    >
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  <tr v-for="(value, index) in returnData" :key="index" :class="tableTdColor(value.status)">
                    <td>
                      <a
                        href="javascript:void(0)"
                        @click="
                          clickExtraInfo(
                            `Payin Detail #${value.deposit_id}`,
                            value
                          )
                        "
                        :title="`${value.deposit_id}`"
                      >
                        {{ value.deposit_id }}
                      </a>
                      <br />{{ value.transaction_id }}
                    </td>
                    <td>
                      {{ value.reference_no }}<br>{{value.merchant_remark || '-'}}
                    </td>
                    <td>
                      {{
                        convertDateByCountry(
                          value.created_datetime,
                          selectedTimezone
                        )
                      }}
                      <br />
                      {{
                        convertDateByCountry(
                          value.updated_datetime || "-",
                          selectedTimezone
                        )
                      }}
                    </td>
                    <td>
                      <div v-if="account_type == 'admin'">
                        <router-link
                          :to="{
                            name: 'merchants',
                            params: { id: value.upline_db_code },
                          }"
                          >#{{ value.merchant_info.merchant_id }}
                          {{ value.merchant_info.merchant_name }}</router-link
                        >
                      </div>
                      <div v-else>
                        #{{ value.merchant_info.merchant_id }}
                        {{ value.merchant_info.merchant_name }}
                      </div>

                      <router-link
                        :to="{
                          name: 'contract ledger with id',
                          params: {
                            resellerid: 'all',
                            id: value.merchant_info.merchant_contract,
                          },
                        }"
                        >{{ value.merchant_info.merchant_contract }}:
                        {{ value.merchant_info.merchant_contract_name }}
                      </router-link>
                    </td>
                    <td v-if="account_type == 'admin'">
                      <div v-if="value.method == 'auto'">
                        <div class="fw-medium" v-if="value.pg_info.pg_name">
                          {{ value.pg_info.pg_name }}
                        </div>
                        <div
                          class="mb-1 text-muted"
                          v-if="value.pg_transaction_id"
                        >
                          {{ value.pg_transaction_id }}
                        </div>
                        <button
                          class="btn btn-sm btn-outline-info"
                          @click="
                            clickExtraInfo(
                              `${value.pg_info.pg_name} - Callback Data`,
                              value.pg_info
                            )
                          "
                        >
                          Callback Data
                        </button>
                      </div>
                      <div v-else>
                        <div class="fw-medium">Manually Top Up</div>
                        <button
                          class="btn btn-sm btn-outline-info"
                          v-if="value.top_up_details"
                          @click="
                            clickExtraInfo(
                              `Top Up Detail #${value.top_up_details.topup_id}`,
                              value.top_up_details
                            )
                          "
                        >
                          Top Up Detail
                        </button>
                      </div>
                    </td>
                    <td>
                      <div v-if="value.method == 'auto'">
                        <span class="badge bg-soft-info font-size-14"
                          >Auto</span
                        >
                      </div>
                      <div v-else>
                        <span
                          class="badge bg-soft-warning font-size-14"
                          v-if="account_type == 'admin'"
                          v-b-tooltip.hover
                          :title="'Processed By ' + value.processed_by"
                          >Manual</span
                        >
                        <span class="badge bg-soft-warning font-size-14" v-else
                          >Manual</span
                        >
                      </div>
                    </td>
                    <td>
                      {{ value.currency }}
                      <span v-if="value.deposit_amount">{{
                        convertCurrencyFormat(value.deposit_amount, true)
                      }}</span>
                    </td>
                    <td>
                      {{ value.currency }}
                      <span v-if="value.total_charges">{{
                        convertCurrencyFormat(value.total_charges, true)
                      }}</span>
                    </td>
                    <td>
                      {{ value.currency }}
                      <span v-if="value.net_amount">{{
                        convertCurrencyFormat(value.net_amount, true)
                      }}</span>
                    </td>
                    <td>
                      {{ value.charges_rate }}% + {{ value.currency }}
                      {{ convertCurrencyFormat(value.extra_charges, true) }}
                    </td>
                    <td>
                      <span
                        class="badge bg-soft-success font-size-14"
                        v-if="value.status == 'success'"
                        >Successful</span
                      >
                      <span
                        class="badge bg-soft-purple font-size-14"
                        v-else-if="value.status == 'pending'"
                        >Processing</span
                      >
                      <span
                        class="badge bg-soft-danger font-size-14"
                        v-else-if="value.status == 'failed'"
                        v-b-tooltip.hover
                        :title="value.reason"
                        >Failed</span
                      >
                    </td>
                    <td class="text-center">
                      <span
                        v-if="
                          create_modify_permission && account_type == 'admin'
                        "
                      >
                        <button
                          class="btn btn-outline-info px-2 py-1 me-1 font-size-15 rounded"
                          v-if="value.status == 'success'"
                          @click="updateCallback(value, 'triggerCallback')"
                          v-b-tooltip.hover
                          title="Trigger Callback"
                        >
                          <i class="uil uil-exchange-alt"></i>
                        </button>

                        <button
                          class="btn btn-outline-info px-2 py-1 me-1 font-size-15 rounded"
                          @click="updateCallback(value, 'trigger2success')"
                          v-b-tooltip.hover
                          title="Trigger to Success"
                          v-if="value.status == 'failed'"
                        >
                          <i class="uil uil-exchange-alt"></i>
                        </button>

                        <button
                          class="btn btn-outline-info px-2 py-1 me-1 font-size-15 rounded"
                          v-if="value.status == 'pending'"
                          v-b-tooltip.hover
                          title="View"
                          @click="updateCallback(value, 'trigger2success')"
                        >
                          <i class="uil uil-exchange-alt"></i>
                        </button>
                      </span>
                      <button
                        class="btn btn-outline-info px-2 py-1 font-size-15 rounded"
                        @click="
                          clickExtraInfo(
                            `Payin Detail #${value.deposit_id}`,
                            value
                          )
                        "
                      >
                        <i class="uil uil-eye"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !pageLoading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
    <extraInfo ref="modalFunc3" :data="{ extraInfo: currentClickedInfo }" :timezone="selectedTimezone"/>
    <updateCallback
      ref="modalFunc4"
      :data="{
        accessUsername: accessUsername,
        assessToken: accessToken,
        updateData: updateData,
      }"
      @callParentFunction="handlePageChange(currentPage)"
    />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import extraInfo from "@/views/pages/app/modal/extraInfo";
import updateCallback from "@/views/pages/app/modal/updateCallback";
/**
 * Page
 */
// const CountryDefault = {
//   id: -1,
//   name: "All Countries",
//   currencyId: 0,
//   currencyName: "",
//   timeZone: [],
// };
const CancelToken = axios.CancelToken;
let cancel;

const PageTitle = "Processing Transactions";
const pgDefault = {
  id: "",
  payment_gateway_name: "All Payment Gateway",
  payment_gateway_code: "",
  currency: "",
  status: "",
};
const CountryDefault = { name: "All Countries", value: "", status: "" };
const MerchantDefault = {
  status: "0",
  payin_rate: 0,
  contract_id: "",
  payout_rate: 0,
  total_payin: 0,
  ewallet_rate: 0,
  total_payout: 0,
  contract_name: "All Contracts",
  contract_total: 0,
  contract_status: "",
  settlement_rate: 0,
  contract_balance: 0,
  total_settlement: 0,
  contract_currency: "",
  payin_extra_charges: 0,
  payout_extra_charges: 0,
  ewallet_extra_charges: 0,
  settlement_extra_charges: 0,
  contract_list_length_total: 0,
  merchant_Id: "",
  account_name: "",
  merchant_status: "",
  reseller_id: "",
  reseller_name: "",
  reseller_code: "",
  reseller_level: 0,
};
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    DatePicker,
    extraInfo,
    updateCallback,
    Multiselect,
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      currentClickedInfo: { title: "", info: {} },
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type: "",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      selectedTimezone: "Malaysia",
      timezoneCountry:appConfig.timezoneCountryList,
      submitted: false,
      loading: false,
      pageLoading:false,
      buttonLoading: [false, false, false],
      currentPage: 1,
      currencySymbol: "₹",
      selectedmerchantContract: MerchantDefault,
      merchantContract: [MerchantDefault],
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedPg: pgDefault,
      pgList: [pgDefault],
      seletedMethod: "",
      selectedStatus: "",
      searchTransactionId: "",
      searchReferenceNo: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnTotalAmount: 0,
      returnTotalTransaction: 0,
      returnSuccessRate: 0,
      export_data: [],
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      extraInfo: {
        payment_gateway_name: "",
      },
      currentMerchantInfo: {},
      currentResellerInfo: {},
      create_modify_permission: false,
      updateData: {},
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.date_range = this.$refs.commonFunc.last30Days();
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
    //if update status from failed to processing then help to search transaction id
    if (this.$route.query.transactionId) {
      this.searchTransactionId = this.$route.query.transactionId;
    }
    this.getMerchantContract();
    this.getCountries();
    if (this.account_type == "admin") {
      this.getPgList();
    }
    await this.handlePageChange(1);
  },
  created() {},
  methods: {
    tableTdColor(status){
      if (status=='success'){
        return 'td-bg-soft-success'
      }
      else if (status=='pending'){
        return 'td-bg-soft-processing'
      }
      else if (status=='failed'){
        return 'td-bg-soft-failed'
      }
    },
    convertDateByCountry(dateTimeString, country) {
      console.log(country)
      return dateTimeString
      // this.$refs.commonFunc.convertDateByCountry(dateTimeString, country);
    },
    updateCallback(value, type) {
      this.updateData = value;
      if (type == "triggerCallback") {
        setTimeout(
          () => this.$refs.modalFunc4.showTriggerCallbackModal("payin"),
          0
        );
      }
      if (type == "trigger2success") {
        setTimeout(
          () => this.$refs.modalFunc4.showTrigger2successModal("payin"),
          0
        );
      }
    },
    clickExtraInfo(title, object) {
      this.currentClickedInfo = { title: "", info: {} };
      this.currentClickedInfo = { title: title, info: object };
      setTimeout(() => this.$refs.modalFunc3.showExtraInfoModal(), 0);
    },
    convertCurrencyFormat(value,show00) {
      if ( this.$refs.commonFunc){
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
            // Assuming this.transactionLimit is defined in your component's data
            return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      }
    },
    viewExtraInfo(data) {
      this.$refs["modal-extra-info"].show();
      this.extraInfo = data;
    },
    resetModal() {
      this.extraInfo = {};
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this[parentModel] = numericValue;
    },
    countryChanged() {
      this.selectedPg = pgDefault;
      this.handlePageChange(1);
    },
    returnPgList() {
      if (this.selectedCountry.value !== "") {
        return this.pgList.filter(
          (element) => element.currency === this.selectedCountry.value
        );
      } else {
        return this.pgList;
      }
    },
    getPgList() {
      console.log("getPgList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getPgListShort",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedPg = pgDefault;
            this.pgList = [this.selectedPg];
            resData.pg_list.forEach((element) => {
              this.pgList.push({
                id: element.id,
                payment_gateway_name: element.payment_gateway_name,
                payment_gateway_code: element.payment_gateway_code,
                currency: element.currency,
                status: element.status === "activated" ? 1 : 0,
              });
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async getCountries() {
      console.log("getCountries");
      this.$Progress.start();
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getSupportedCurrency",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            (this.selectedCountry = CountryDefault),
              (this.countryList = [this.selectedCountry]);
            resData.data.forEach((element) => {
              this.countryList.push(element);
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
            this.loading = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            this.loading = false;
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getMerchantContract() {
      console.log("getMerchantContract");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999999");
      bodyFormData.append(
        "reseller",
        this.account_type === "reseller"
          ? this.currentResellerInfo.account_db_code
          : "all"
      );
      bodyFormData.append(
        "merchant",
        this.account_type === "merchant"
          ? this.currentMerchantInfo.account_db_code
          : "all"
      );
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getContractListForMerchant",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedmerchantContract = MerchantDefault;
            this.merchantContract = [this.selectedmerchantContract];

            if (this.account_type !== "merchant") {
              resData.data.forEach((element) => {
                this.merchantContract.push(element);
              });
            }
            if (this.account_type == "merchant") {
              resData.data.forEach((element) => {
                if (element.merchant_Id == this.currentMerchantInfo.id) {
                  this.merchantContract.push(element);
                }
              });
            }
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    notBeforeToday(date) {
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return date > tomorrow;
      //return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum);
      //this.exportData(1)
    },

    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    clear() {
      this.selectedCountry = CountryDefault;
      this.selectedmerchantContract = MerchantDefault;
      this.selectedPg = pgDefault;
      this.seletedMethod = "";
      this.selectedStatus = "";
      this.searchTransactionId = "";
      this.searchReferenceNo = "";
      this.filterType = "created_date";
      this.searchAmount = "";
      this.date_range = this.$refs.commonFunc.last30Days();

      this.handlePageChange(1);
      this.currentPage = 1;
    },

    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = [];
      objectMaxLength.forEach((value, index) => {
        console.log(value);
        wscols.push({ width: objectMaxLength[index] });
      }),
        (ws["!cols"] = wscols);

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name);
      window.XLSX.writeFile(wb, title + "." + (type || "xlsx"));
    },
    async getData(pages) {
      if (cancel !== undefined) cancel();
      this.$Progress.start();
      this.pageLoading = true;
      this.disabled = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", pages);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("filterBy", this.filterType);
      // bodyFormData.append("dateStart", this.date_range[0]);
      // bodyFormData.append("dateEnd", this.date_range[1]);
      bodyFormData.append("dateStart", "");
      bodyFormData.append("dateEnd", "");
      bodyFormData.append("currency", this.selectedCountry.value);
      bodyFormData.append("pg", this.selectedPg.payment_gateway_code);
      bodyFormData.append("transactionId", this.searchTransactionId);
      bodyFormData.append("referenceNo", this.searchReferenceNo);
      bodyFormData.append(
        "merchantContractId",
        this.selectedmerchantContract.contract_id
      );
      bodyFormData.append("status", "pending");
      bodyFormData.append("amount", this.searchAmount);
      bodyFormData.append("method", this.seletedMethod);
      bodyFormData.append("timeZone", this.selectedTimezone);
      console.log(
        this.selectedmerchantContract.contract_id,
        this.seletedMethod,
        this.selectedStatus,
        this.selectedCountry.value,
        this.selectedPg.payment_gateway_code,
        this.searchTransactionId,
        this.searchReferenceNo,
        this.searchAmount,
        this.filterType,
        this.date_range[0],
        this.date_range[1]
      );
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getAllPayinList",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken((c) => (cancel = c)),
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.returnData = [];
            console.log(resData);
            this.returnData = resData.data;
            this.totalData = resData.total;
            this.returnTotalAmount = resData.total_amount;
            this.returnTotalTransaction = resData.total_success_transaction;
            this.returnSuccessRate = resData.success_rate;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.pageLoading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.pageLoading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          console.log(error);
        });
    },
    async exportData() {
      this.$Progress.start();
      this.disableExport = true;
      this.loadingButton = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone", this.accessPhone);
      // bodyFormData.append("page", pages);
      // bodyFormData.append("limit", this.perPage);
      axios({
        method: "get",
        url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.export_data = [];
          var resData = response.data;
          this.totalData = resData.total;
          var users = resData.users;

          for (var i in users) {
            // Combine date and time
            const depositDateTime = `29 Jun 2023, 12:30:00`;
            // Generate a random deposit amount
            const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000

            // Calculate merchant fee and nett amount
            const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
            const merchantFee = Math.floor(
              randomDeposit * merchantFeePercentage
            );
            const nettAmount = randomDeposit - merchantFee;
            const randomStatus = Math.random() < 0.8 ? "success" : "failed";
            var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
            var transaction_id = "";
            if (randomMode == "UPI") {
              transaction_id = "320020673657";
            } else {
              transaction_id = "IMPS/123443211240";
            }

            const listData = {
              id: "ids",
              deposit_date: depositDateTime,
              customer_id: `${users[i].id}`,
              customer_name: `${users[i].firstName} ${users[i].lastName}`,
              deposit_amount: randomDeposit.toString(),
              deposit_mode: randomMode.toString(),
              merchant_fee: merchantFee.toString(),
              nett_amount: nettAmount.toString(),
              transaction_id: transaction_id,
              status: randomStatus,
            };
            this.export_data.splice(i, 0, listData);
          }
          var self = this;
          setTimeout(function () {
            self.disableExport = false;
            self.loadingButton = false;
            self.exportToExcel(self.export_data, "Summary", "xlsx");
          }, 1200);

          // if (resData.status == 200) {
          //   console.log(resData)
          //   this.totalData = 10
          // }
          // else if (resData.status == 440){
          //   Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          //     localStorage.clear();
          //     this.$router.push({
          //         path: "/login",
          //   });
          // }
          // else {
          //     Swal.fire(
          //       {
          //       icon: 'error',
          //       title: 'Oops...',
          //       html: `${resData.message}.`,
          //       confirmButtonColor: '#222',
          //       confirmButtonText: this.$t('siteLang.Done'),
          //     })
          // }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disableExport = false;
          this.loadingButton = false;
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          this.account_type = data.account_type; //data.account_type;
          this.selectedTimezone = data.time_zone;
          const permission = data.modules.filter((e) => e.name === "payin");
          if (permission) {
            this.create_modify_permission =
              permission[0].create_modify_permission == 0 ? false : true;
          } else {
            console.log('Module with name "payin" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
